<template>
  <v-app id="home" style="min">
    <NavBar />
      <div class="home-content-overlay" ref="contentOverlay">
        <div class="content-text">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="6">
                <h1>BELT LOGISTIK <span style="color:red"> INDONESIA </span></h1>
                <p>
                  Selamat datang di Belt Logistics Indonesia! Kami adalah mitra logistik terpercaya Anda dengan pengalaman lebih dari satu dekade. Dari transportasi bahan berbahaya hingga pengiriman alat kesehatan, kami siap membantu Anda menyelesaikan kebutuhan logistik Anda dengan cepat dan efisien. Bergabunglah dengan kami untuk pengalaman logistik yang lancar dan terpercaya!
                </p>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <v-container>
        <div style="position: relative; margin-bottom: 50px; margin-top: 10px; text-align: center;">
            <h2>Mengapa Memilih Jasa Kami</h2>
            <span style="position: absolute; bottom: -10px; left: 50%; transform: translateX(-50%); width: 100px; border-bottom: 4px solid red;"></span>
        </div>
        <v-row align="center" justify="center">
          <v-col cols="12" md="3">
            <div class="content-box">
              <v-icon size="70" class="fas fa-shield-alt" color="red" style="margin-bottom: 30px;"></v-icon>
              <h3>Keandalan</h3>
              <p>Kami menjamin pengiriman Anda sampai tujuan dengan keandalan tinggi.</p>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div class="content-box">
              <v-icon size="70" class="fa fa-key" color="red" style="margin-bottom: 30px;"></v-icon>
              <h3>Kemudahan</h3>
              <p>Kami menyediakan layanan yang mudah digunakan dan dipahami oleh pelanggan.</p>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div class="content-box">
              <v-icon size="70" class="far fa-smile" color="red" style="margin-bottom: 30px;"></v-icon>
              <h3>Kepuasan Pelanggan</h3>
              <p>Kepuasan pelanggan adalah prioritas utama kami, kami selalu siap membantu.</p>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div class="content-box">
              <v-icon size="70" class="far fa-clock" color="red" style="margin-bottom: 30px;"></v-icon>
              <h3>Ketepatan Waktu</h3>
              <p>Kami menjamin pengiriman Anda akan tepat waktu sesuai dengan jadwal yang ditentukan.</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container style="background-color: white; padding: 30px; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); margin-top:50px; margin-bottom:50px;">
        <v-row align="center">
          <v-col cols="12" md="5" class="trackingImg"></v-col>
          <v-col cols="12" md="7">
            <!-- Deskripsi di sebelah kanan -->
            <div class="tracking-description" style="font-family: 'Poppins', sans-serif; padding:20px;">
              <h1 style="font-family: 'Poppins', sans-serif;">Pantau Pengiriman <span style="color:red;">Anda !</span></h1>
              <p style="font-family: 'Poppins', sans-serif;">
                Selamat datang di pusat informasi pengiriman kami! Di sini, Anda dapat memantau dan melacak setiap langkah perjalanan paket Anda dengan mudah dan cepat. Dapatkan informasi terkini secara real-time tentang status pengiriman paket Anda untuk memastikan sampai dengan aman di tujuan. Temukan kemajuan pengiriman Anda dengan sekali klik, dan nikmati pengalaman pengiriman yang lebih lancar dengan layanan pelacakan kami yang andal.
              </p>
              <!-- Tombol "Go to Tracking Page" di bawah deskripsi -->
              <button class="trackingBtn" style="margin-top:50px;">
                <router-link to="/tracking" class="button-link">Go to Tracking Page</router-link>
              </button>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="footer-container">
        <FooterSection />
      </div>
      <div class="whatsapp-button">
      <a href="https://wa.me/0" target="_blank" class="whatsapp-link">
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/479px-WhatsApp_icon.png" alt="WhatsApp Icon" class="whatsapp-icon" />
      </a>
    </div>
  </v-app>
</template>

<script>
import { defineComponent } from 'vue';
import NavBar from '@/components/NavBar.vue';
import FooterSection from '@/components/FooterSection.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    NavBar,
    FooterSection
  },
  mounted() {
    this.adjustImageHeight();
    this.changeImage();
    window.addEventListener('resize', this.adjustImageHeight);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.adjustImageHeight);
  },
  methods: {
    adjustImageHeight() {
      const contentOverlay = this.$refs.contentOverlay;
      if (contentOverlay) {
        contentOverlay.style.height = `500px`;
      }
    },
    preloadImages(urls) {
      urls.forEach(url => {
          const img = new Image();
          img.src = url;
      });
    },
    changeImage() {
      var currentIndex = 0;
      var contentOverlay = document.querySelector('.home-content-overlay');
      var images = [
          "image1",
          "image2",
          "image3",
          "image4"
      ];

      // Preload images
      this.preloadImages(images);

      setInterval(() => {
          currentIndex = (currentIndex + 1) % images.length;
          contentOverlay.style.opacity = 2;
          setTimeout(() => {
              contentOverlay.className = 'home-content-overlay ' + images[currentIndex]; 
          }, 1000); 
      }, 10000);
    }
  }
});

</script>

<style>
@import '../assets/styles/home.css';
.trackingBtn {
  background-color: red;
  color: white; 
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
}

.trackingBtn a {
  text-decoration: none;
  color: white;
}
.trackingBtn:hover {
  background-color: darkred;
}

.trackingImg {
  height: 500px;
  background-image: url('../assets/img/shippingTracking.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.content-box {
  padding: 20px;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.content-box:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.home-content-overlay.image1 {
    background-image: url("../assets/img/logistik1.png");
}

.home-content-overlay.image2 {
    background-image: url("../assets/img/shipp.png");
}

.home-content-overlay.image3 {
    background-image: url("../assets/img/logistik2.png");
}

.home-content-overlay.image4 {
    background-image: url("../assets/img/ship.png");
}
</style>