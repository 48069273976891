<template>
  <v-container>
    <div class="search-tracking-bar">
      <h2 class="ml-5 mb-5">Tracking</h2>
      <v-row>
        <v-col cols="12" md="8">
          <v-text-field
            class="mx-4"
            counter="16"
            :error-messages="message"
            type="number"
            inputmode="numeric"
            v-model="searchQuery"
            label="No Resi"
            outlined
            dense
          />
        </v-col>
        <v-col cols="12" md="12">
          <v-btn class="ml-4" color="error" @click="search" small>Search</v-btn>
        </v-col>
        <v-col cols="12" md="12">
          <v-container fluid>
            <div v-if="loading">
              <v-row justify="center" class="my-5">
                <v-col cols="auto">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-col>
              </v-row>
            </div>
            <v-table v-if="searchResult" class="styled-table">
              <thead>
                <tr>
                  <th class="text-left">Status</th>
                  <th class="text-left">Remarks</th>
                  <th class="text-left">Date</th>
                  <th class="text-left">Image</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in searchResult" :key="index">
                  <td>{{ item.status }}</td>
                  <td v-html="item.note"></td>
                  <td>{{ formatDate(item.date) }}</td>
                  <td :rowspan="searchResult.length" v-if="searchResult.length && index === 0" class="center-image">
                    <img :src="image" alt="Image" class="image-style">
                  </td>
                </tr>
              </tbody>  
            </v-table>
            <v-img
              v-if="searchResult === undefined"
              src="../assets/3747371.png"
              aspect-ratio="2.5"
              contain
            ></v-img>
          </v-container>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SearchTrackingBar',
  data() {
    return {
      image: '',
      searchQuery: '',
      searchResult: null,
      message: null,
      notFound: false,
      loading: false,
    };
  },
  methods: {
    formatDate(dateString) {
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString('id-ID', options);
    },
    async search() {
      if (this.searchQuery.length > 16) {
        this.message = 'Angka tidak boleh melebihi batas maksimum !';
        return;
      } else {
        this.message = null
      }
      
      try {
        this.loading = true;
        const response = await axios.get(`https://axioma-func.azurewebsites.net/api/Betlog_Tracking?tracking=${this.searchQuery}`);
        this.searchResult = response.data.tracking?.reverse();
        this.searchResult?.forEach(item => {
          item.note = item.note.replace(/\n/g, '<br>');
        });
        this.image = `../img/${this.searchQuery}.jpg`
        this.notFound = false;
      } catch (error) {
        this.searchResult = null;
        this.notFound = true;
        console.error('Error fetching data:', error);
      } finally {
        this.loading = false;
      }
    }
  },
});

</script>
<style scoped>
.search-tracking-bar {
  background-color: #f5f5f5;
  padding: 20px 0;
}

.headline {
  font-size: 18px;
  font-weight: bold;
}

.v-overlay__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.styled-table {
  border-collapse: collapse;
  width: 100%;
}

.styled-table th, .styled-table td {
  border: 1px solid #dddddd;
  padding: 8px;
}

.styled-table th {
  background-color: #f2f2f2;
}

.center-image {
  text-align: center;
  vertical-align: middle;
}

.image-style {
  width: 200px;
  height: 200px;
}
</style>
