<template>
  <v-app id="home" style="min">
    <NavBar />
      <div class="content-overlay" ref="contentOverlay">
        <div class="content-text">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="6">
                <h1>BELT LOGISTIK <span style="color:red"> INDONESIA </span></h1>
                <p>
                  Berdiri di Jakarta pada tanggal 14 Maret 2011, PT Belt Logistik Indonesia adalah perusahaan logistik
                  dan distribusi khusus dalam penanganan barang-barang berbahaya, seperti transportasi bahan peledak
                  dan transportasi limbah B3.
                  Belt Logistics memiliki beberapa layanan, seperti moving, personal effect removal, distribusi ATM, dan
                  pengiriman alat-alat kesehatan. Secara umum, Belt Logistics melayani berbagai pengiriman dengan
                  moda udara, laut, dan darat.
                </p>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <v-container>
        <v-row>
          <v-col>
            <div class="search-tracking-bar-container">
              <SearchTrackingBar />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="footer-container">
        <FooterSection />
      </div>
      <div class="whatsapp-button">
      <a href="https://wa.me/0" target="_blank" class="whatsapp-link">
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/479px-WhatsApp_icon.png" alt="WhatsApp Icon" class="whatsapp-icon" />
      </a>
    </div>
  </v-app>
</template>

<script>
import { defineComponent } from 'vue';
import NavBar from '@/components/NavBar.vue';
import FooterSection from '@/components/FooterSection.vue';
import SearchTrackingBar from '@/components/SearchTrackingBar.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    NavBar,
    SearchTrackingBar,
    FooterSection
  },
 mounted() {
    this.adjustImageHeight();
    this.changeImage();
    window.addEventListener('resize', this.adjustImageHeight);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.adjustImageHeight);
  },
  methods: {
    adjustImageHeight() {
      const contentOverlay = this.$refs.contentOverlay;
      if (contentOverlay) {
        contentOverlay.style.height = `500px`;
      }
    },
    changeImage() {
      var currentIndex = 0;
      var contentOverlay = document.querySelector('.content-overlay');
      var images = [
          "image1",
          "image2",
          "image3",
          "image4"
      ];

      setInterval(() => {
          currentIndex = (currentIndex + 1) % images.length;
          setTimeout(() => {
              contentOverlay.className = 'content-overlay ' + images[currentIndex];
          }, 1000);
      }, 10000);
    }
  },
});
</script>

<style>
@import '../assets/styles/home.css';

.content-overlay.image1 {
    background-image: url("../assets/img/logistik1.png");
}

.content-overlay.image2 {
    background-image: url("../assets/img/shipp.png");
}

.content-overlay.image3 {
    background-image: url("../assets/img/logistik2.png");
}

.content-overlay.image4 {
    background-image: url("../assets/img/ship.png");
}
</style>