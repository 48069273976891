<template>
  <v-app>
    <v-app-bar app color="error" dark>
      <v-row align="center" justify="space-between" no-gutters>
        <v-col cols="12" md="3" class="ml-5 d-flex align-center">
          <v-col cols="4" md="3" class="ml-5">
            <v-img class="img-size" src="../assets/beltlogo.png"></v-img>
          </v-col>
          <v-col class="text-size" cols="4" md="2">
            <v-btn text>Belt Logistics</v-btn>
          </v-col>
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-end">
          <router-link to="/" class="nav-item" :class="{ 'active': $route.path === '/' }">Home</router-link>
          <router-link to="/tracking" class="nav-item" :class="{ 'active': $route.path === '/tracking' }">Tracking</router-link>
        </v-col>
      </v-row>
    </v-app-bar>
  </v-app>
</template>

<script>
export default {
};
</script>

<style>
.nav-item {
  margin-right: 50px; /* Add right margin to create space */
  text-decoration: none; /* Remove default underline */
  color: inherit; /* Inherit text color */
}

.nav-item.active {
  font-weight: bold; /* Change font weight for active nav item */
}

.nav-item:hover {
  color: #000000; /* Change color on hover */
}
</style>
