<template>
  <footer class="footer mt-5">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <div>
            <v-img width="150px" height="150px" src="../assets/beltlogofooter.png"></v-img>
            <p style="font-size:12px;">
              “easy, safe, reliable” atau mudah, aman, terpercaya selalu menjadi motivasi kami dalam menghadapi tantangan di era globalisasi. Karena itu kami selalu menerapkan metode kerja yang efektif dan efisien agar dapat memberikan pelayanan optimal. Dengan didukung oleh tenaga kerja profesional dan terlatih, kami siap menghadapi tantangan mengembangkan pelayanan dan memberikan solusi optimal bagi kebutuhan logistik anda.
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="additional-info">
            <h2 style="font-size: 16px; color: white;">Info Tambahan</h2>
            <p style="font-size: 12px; color: white;">Layanan Pelanggan: 24/7</p>
            <p style="font-size: 12px; color: white;">Bergabunglah dengan Kami: <a href="#" class="decoration" style="color: white;">Karir</a></p>
            <p style="font-size: 12px; color: white;">Follow Kami: <a href="#" class="decoration" style="color: white;">LinkedIn</a></p>
          </div>
        </v-col>
      </v-row>
      <hr class="mt-5" color="white" style="opacity: 0.2">
      <v-row>
          <v-col cols="12" md="6">
            <span style="color: white; font-size: 13px;">&copy; Belt Logistics 2024</span>
          </v-col>
          <v-col cols="12" md="6" class="socicon">
            <a href="#" class="social-icon"><i class="fab fa-facebook-f"></i></a>
            <a href="#" class="social-icon"><i class="fab fa-twitter"></i></a>
            <a href="#" class="social-icon"><i class="fab fa-instagram"></i></a>
          </v-col>
        </v-row>
    </v-container>
  </footer>
</template>

<script>
export default {
};
</script>

<style>
</style>
